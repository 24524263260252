import { default as _91id_93XHiXqcC7JrMeta } from "/home/forge/merchants.smake.construction/builds/pages/[slug]/catalog/logos/[id].vue?macro=true";
import { default as indexWYJdp9z02iMeta } from "/home/forge/merchants.smake.construction/builds/pages/[slug]/catalog/logos/index.vue?macro=true";
import { default as _91id_93zGVDyWSMYiMeta } from "/home/forge/merchants.smake.construction/builds/pages/[slug]/customers/[customer]/logos/[id].vue?macro=true";
import { default as indexTJ2tzI7QyfMeta } from "/home/forge/merchants.smake.construction/builds/pages/[slug]/customers/[customer]/logos/index.vue?macro=true";
import { default as colorsrNczJmFcgqMeta } from "/home/forge/merchants.smake.construction/builds/pages/[slug]/customers/[id]/colors.vue?macro=true";
import { default as favorite_45variantsI230friVPDMeta } from "/home/forge/merchants.smake.construction/builds/pages/[slug]/customers/[id]/favorite-variants.vue?macro=true";
import { default as indexLcCOa82l4GMeta } from "/home/forge/merchants.smake.construction/builds/pages/[slug]/customers/[id]/index.vue?macro=true";
import { default as indexmjqWYFAbmKMeta } from "/home/forge/merchants.smake.construction/builds/pages/[slug]/customers/index.vue?macro=true";
import { default as dashboard8Y9b7GofGxMeta } from "/home/forge/merchants.smake.construction/builds/pages/[slug]/dashboard.vue?macro=true";
import { default as entranceESprU1DGwMMeta } from "/home/forge/merchants.smake.construction/builds/pages/[slug]/entrance.vue?macro=true";
import { default as index038ZUGbPk6Meta } from "/home/forge/merchants.smake.construction/builds/pages/[slug]/index.vue?macro=true";
import { default as loginO6eEXxnQs7Meta } from "/home/forge/merchants.smake.construction/builds/pages/[slug]/login.vue?macro=true";
import { default as indexQtkuwDAbEdMeta } from "/home/forge/merchants.smake.construction/builds/pages/index.vue?macro=true";
export default [
  {
    name: "slug-catalog-logos-id",
    path: "/:slug()/catalog/logos/:id()",
    meta: _91id_93XHiXqcC7JrMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/builds/pages/[slug]/catalog/logos/[id].vue")
  },
  {
    name: "slug-catalog-logos",
    path: "/:slug()/catalog/logos",
    meta: indexWYJdp9z02iMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/builds/pages/[slug]/catalog/logos/index.vue")
  },
  {
    name: "slug-customers-customer-logos-id",
    path: "/:slug()/customers/:customer()/logos/:id()",
    meta: _91id_93zGVDyWSMYiMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/builds/pages/[slug]/customers/[customer]/logos/[id].vue")
  },
  {
    name: "slug-customers-customer-logos",
    path: "/:slug()/customers/:customer()/logos",
    meta: indexTJ2tzI7QyfMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/builds/pages/[slug]/customers/[customer]/logos/index.vue")
  },
  {
    name: "slug-customers-id-colors",
    path: "/:slug()/customers/:id()/colors",
    meta: colorsrNczJmFcgqMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/builds/pages/[slug]/customers/[id]/colors.vue")
  },
  {
    name: "slug-customers-id-favorite-variants",
    path: "/:slug()/customers/:id()/favorite-variants",
    meta: favorite_45variantsI230friVPDMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/builds/pages/[slug]/customers/[id]/favorite-variants.vue")
  },
  {
    name: "slug-customers-id",
    path: "/:slug()/customers/:id()",
    meta: indexLcCOa82l4GMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/builds/pages/[slug]/customers/[id]/index.vue")
  },
  {
    name: "slug-customers",
    path: "/:slug()/customers",
    meta: indexmjqWYFAbmKMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/builds/pages/[slug]/customers/index.vue")
  },
  {
    name: "slug-dashboard",
    path: "/:slug()/dashboard",
    component: () => import("/home/forge/merchants.smake.construction/builds/pages/[slug]/dashboard.vue")
  },
  {
    name: "slug-entrance",
    path: "/:slug()/entrance",
    meta: entranceESprU1DGwMMeta || {},
    component: () => import("/home/forge/merchants.smake.construction/builds/pages/[slug]/entrance.vue")
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/home/forge/merchants.smake.construction/builds/pages/[slug]/index.vue")
  },
  {
    name: "slug-login",
    path: "/:slug()/login",
    meta: loginO6eEXxnQs7Meta || {},
    component: () => import("/home/forge/merchants.smake.construction/builds/pages/[slug]/login.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/merchants.smake.construction/builds/pages/index.vue")
  }
]